import { setUtil } from "../SetUtils/SetUtils";

class TreeNode {
  constructor(parent, object) {
    this.parent = parent;
    this.object = object;
    this.children = [];

    if (parent) {
      parent.children.push(this);
    }
  }

  traverse = () => {
    let result = [this];

    for (let child of this.children) {
      result = [...result, ...child.traverse()];
    }

    return result;
  };

  traverseToRoot = () => {
    let node = this;
    let path = [node];

    while (node.parent) {
      node = node.parent;
      path.push(node);
    }

    return path;
  };
}

class Tree {
  constructor(root, nodesHash) {
    this.root = root;
    this.nodesHash = nodesHash;
  }
}

class DropdownTreeMeta {
  constructor(referenceTree, dropdownTree, dropdownTreeNodesHash) {
    this.referenceTree = referenceTree;
    this.dropdownTree = dropdownTree;
    this.dropdownTreeNodesHash = dropdownTreeNodesHash;
  }
}

class TreeUtil {
  constructTree(allOrgs) {
    if (!allOrgs) throw new Error("Missing argument allOrgs");

    let root = null;
    const orgsHash = {};
    const nodesHash = {};

    for (let org of allOrgs) {
      orgsHash[org.id] = org;

      if (!org.organization) {
        root = new TreeNode(null, org);
      }
    }

    if (root === null) {
      throw new Error("Tree root could not be determined");
    }

    nodesHash[root.object.id] = root;

    for (let org of allOrgs) {
      if (org.id in nodesHash) {
        // org already added
        continue;
      }

      let orgsToRoot = [org];
      let parentOrgId = org.organization;

      while (parentOrgId) {
        let parentOrg = orgsHash[parentOrgId];
        orgsToRoot.push(parentOrg);
        parentOrgId = parentOrg.organization;
      }

      orgsToRoot.reverse(); // start from root

      for (let i = 0; i <= orgsToRoot.length - 1; i++) {
        let org = orgsToRoot[i];
        let intermittentParentNode;
        let intermittentNode;

        if (org.id in nodesHash) {
          // org already added
          continue;
        }

        if (org.organization in nodesHash) {
          // if parent node was already added
          intermittentParentNode = nodesHash[org.organization];
        }

        intermittentNode = new TreeNode(intermittentParentNode, org);
        nodesHash[org.id] = intermittentNode;
      }
    }

    return new Tree(root, nodesHash);
  }

  createTreeForDropdown = (orgData, checkedIds = [], orgLabelFunc, tenant, orgTitle) => {
    if (!orgData) throw new Error("Missing argument orgData");
    const referenceTree = this.constructTree(orgData);
    const orgs = JSON.parse(JSON.stringify(orgData)); // copy

    const dropdownTreeNodesHash = orgs?.reduce((idMap, org) => {
      idMap[org.id] = org;
      return idMap;
    }, {});

    let dropdownTree;
    for (let org of orgs) {
      if (!org.organization) {
        dropdownTree = org;
      } else {
        let parentOrganization = dropdownTreeNodesHash[org.organization];

        if (!parentOrganization.children?.includes(org)) {
          parentOrganization.children = [...(parentOrganization.children || []), org];
        }
      }
    }
    orgs.forEach((org) => {
      org.checked = !!checkedIds?.includes(org.id);
      org.label = orgLabelFunc ? orgLabelFunc(org) : tenant == "psa" ? org.name : org.displayName;
      org.expanded = true;
      org.disabled = false;

      if (org.children && org.children.length > 0) {
        org.children.forEach((child) => {
          child.disabled = false;
        });
      }
    });
    orgs.forEach((org) => {
      if (org.disableUserCreation === true) {
        org.title = orgTitle;
        org.disabled = true;
        let parentNode = org.parent;
        while (parentNode) {
          parentNode.disabled = true;
          parentNode.title = orgTitle;
          parentNode = parentNode.parent;
        }
      }
    });

    return new DropdownTreeMeta(referenceTree, dropdownTree, dropdownTreeNodesHash);
  };

  selectDeep = (nodeId, action, dropdownTreeMeta) => {
    if (!nodeId) throw new Error("Missing argument 'nodeId'");
    if (action !== "select" && action !== "deselect") throw new Error(`Invalid value for 'action': ${action}`);
    if (!dropdownTreeMeta) throw new Error("Missing argument 'dropdownTree'");

    const referenceTree = dropdownTreeMeta.referenceTree;
    const dropdownTreeNodesHash = dropdownTreeMeta.dropdownTreeNodesHash;

    const currentlySelectedIds = new Set(
      Object.keys(dropdownTreeNodesHash)
        .map((key) => dropdownTreeNodesHash[key])
        .filter((org) => org.checked)
        .map((org) => org.id)
    );

    const subtreeIds = new Set(referenceTree.nodesHash[nodeId].traverse().map((node) => node.object.id));

    const result = Array.from(
      action === "select"
        ? setUtil.union(currentlySelectedIds, subtreeIds)
        : setUtil.difference(currentlySelectedIds, subtreeIds)
    );
    return result;
  };
}

const treeUtil = new TreeUtil();

export { treeUtil, TreeNode };
