import { useQuery } from "@tanstack/react-query";
import OrganizationServiceProvider from "../../services/OrganizationServiceProvider/OrganizationServiceProvider";

function OrganizationList() {
  const organizationService = OrganizationServiceProvider();
  const {
    data: allOrgs,
    isFetching: isFetchingOrgs,
    isLoading: isLoadingOrgs,
    error,
    isError,
  } = useQuery({
    queryKey: ["organizations"],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: async () => {
      const response = await organizationService.getOrganization("organizations");
      return response;
    },
  });
  return { allOrgs, error, isFetchingOrgs, isLoadingOrgs, isError };
}

export default OrganizationList;
