import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";

function UserAddedSuccessPopUp() {
  const { successUserName } = useContext(DataContext);

  return <div className="success-msg">{`${successUserName} added successfully`}</div>;
}

export default UserAddedSuccessPopUp;
