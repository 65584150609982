import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import { Grid, Stack, Container } from "@mui/material";
import DualListBoxSkeleton from "../DualListBoxSkeleton/DualListBoxSkeleton";
import { useTenant } from "../../contexts/TenantService";

export default function AddEditUserSkeleton() {
  const { tenantSvc } = useTenant();
  const isEntityPresent = tenantSvc?.featureToggle?.isEntityPresent;

  return (
    <Container maxWidth={false} sx={{ marginTop: "5%" }} data-testid="AddEditUserSkeleton">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Stack direction="row">
            <Skeleton variant="rectangular" width={20} height={20} sx={{ m: "10px 20px" }} />
            <Skeleton variant="rectangular" width={80} height={20} sx={{ m: "10px 20px" }} />
          </Stack>
        </Grid>

        <Grid item xs={10} />
      </Grid>
      <Paper sx={{ padding: "30px 20px" }}>
        <Container maxWidth={false} padding="5px">
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
            {[...Array(6)].map(() => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={Math.random()}>
                <Skeleton variant="rectangular" width={50} height={8} sx={{ mb: "15px", mt: "10px" }} />
                <Skeleton variant="rounded" width="100%" height={50} />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} alignItems="start" sx={{ mt: "40px" }}>
            <Grid item xs={12} sm={12} md={isEntityPresent ? 6 : 12} lg={isEntityPresent ? 4 : 6}>
              <Skeleton variant="rectangular" width={50} height={8} sx={{ mb: "15px" }} />
              <DualListBoxSkeleton />
            </Grid>

            {isEntityPresent && (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Skeleton variant="rectangular" width={50} height={8} sx={{ mb: "15px" }} />
                <DualListBoxSkeleton />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={6} lg={2} sx={{ padding: "16px 30px" }}>
              <Skeleton variant="rectangular" width={50} height={8} sx={{ mb: "15px" }} />
              <Skeleton variant="rounded" width={200} height={200} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
              <Skeleton variant="rectangular" width={50} height={8} sx={{ mb: "15px" }} />
              <Skeleton variant="rounded" width={100} height={50} />
            </Grid>
            <Grid item xs={4} sm={4}></Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
}
