import {
  CustomParentBox,
  LeftBannerBoxParent,
  RightBannerBoxParent,
} from "../../features/CustomInputFields/CustomInputFields";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import "./StatusScreen.css";
import { useParams } from "react-router";

function StatusScreen({ status }) {
  const { tenant } = useParams();
  return (
    <CustomParentBox className="fpPage cred-mgmt">
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid item xs={7} sx={{ height: "100%", ml: "0", p: "0", display: { xs: "none", sm: "block" } }}>
          <LeftBannerBoxParent className="left-box-wrapper">
            <Box
              className={tenant}
              id="worldline"
              component="img"
              alt="CredentialManagementBanner"
              src={process.env.PUBLIC_URL + `/static/images/${tenant}/CredsManagementBanner.png`}
            />
          </LeftBannerBoxParent>
        </Grid>
        <Grid item sm={5} className="right-wrapper" id={tenant}>
          <RightBannerBoxParent>
            <Box
              component="img"
              sx={{ height: 50 }}
              alt="logo"
              margin="10px"
              src={process.env.PUBLIC_URL + "/static/images/" + tenant + "/banner_logo.png"}
              data-testid="logo"
              className="logo-dynamic"
            />
            <Box>
              <Typography sx={{ fontFamily: "Nunito", whiteSpace: "pre-wrap" }}>{status.message}</Typography>
            </Box>
            <Box>
              <a style={{ fontFamily: "Nunito" }} href={status.goto}>
                {status.goto}
              </a>
            </Box>
          </RightBannerBoxParent>
        </Grid>
      </Grid>
    </CustomParentBox>
  );
}

export default StatusScreen;

StatusScreen.propTypes = {
  status: PropTypes.object,
};
