import { Card, Typography, Grid, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { LeftDrawerList } from "../LeftDrawerList";
import "./DashBoard.css";
import { UseIdentityUser } from "../../helpers/UseIdentityUser";

function DashBoard() {
  var [date, setDate] = useState(new Date());
  const { identityUser } = UseIdentityUser();
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });
  const time = date.getHours();
  const locale = [].concat(navigator.languages);
  const weekDay = date.toLocaleDateString(locale, { weekday: "long" });
  let greetMsg = "";
  if (time >= 1 && time < 12) {
    greetMsg = "morning";
  } else if (time >= 12 && time < 18) {
    greetMsg = "afternoon";
  } else {
    greetMsg = "evening";
  }

  return (
    <>
      <LeftDrawerList isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen} />
      <Grid container sx={{ mt: "3%" }} spacing={1} className="dashboardIconButton">
        <Grid item xs={isLeftDrawerOpen ? 0 : 1}>
          <IconButton
            edge="start"
            onClick={() => setIsLeftDrawerOpen(true)}
            sx={{ justifyContent: "flex-start", display: "flex" }}
          >
            <Typography
              component="img"
              height="15px"
              src={process.env.PUBLIC_URL + "/static/images/drawer_menu_icon.png"}
              className="expand-left-bar-icon"
            />
          </IconButton>
        </Grid>
      </Grid>
      <Card
        data-testid="tenant-context-provider"
        className="dashboardaPage"
        sx={{
          padding: "2% 0 20% 2%",
          mt: "2%",
        }}
        style={
          isLeftDrawerOpen ? { marginLeft: "250px", width: "calc(100% - 335px)" } : { marginLeft: "41px", width: "92%" }
        }
      >
        <Typography variant="h4">
          Good {greetMsg}, {identityUser && identityUser?.givenName + " " + identityUser?.familyName}
        </Typography>
        <p data-testid="date-time">
          {weekDay} , {date.toLocaleTimeString({ month: "long" })} , {date.toLocaleDateString()}
        </p>
      </Card>
    </>
  );
}

export default DashBoard;
