import { Box, Button, Grid } from "@mui/material";
import { MRT_GlobalFilterTextField } from "material-react-table";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandableCell from "../../helpers/ExpandableCell/ExpandableCell";
import GenerateCsv from "../GenerateCsv/GenerateCsv";

import { BaseTable } from "../BaseTable";

const RoleAssignmentTable = ({ data, setTotalData, dataLoading, rawData }) => {
  if (!data || data == undefined || !rawData || rawData == undefined) {
    setTotalData({ before: 0, after: 0 });
    return <div>No data available</div>;
  } else {
    const [isDownloadOpen, setIsDownloadOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (typeof window !== "undefined" || !data) {
        setIsLoading(dataLoading);
      }
    }, [data, dataLoading]);

    const processedData = useMemo(() => {
      const chunkSize = 100;
      const result = [];

      for (let i = 0; i < data?.length; i += chunkSize) {
        const chunk = data.slice(i, i + chunkSize);
        const processedChunk = chunk.map((row) => ({
          ...row,
          searchableRoles: (row.roleNames || []).join(" "),
          searchableSystemRoles: (row.systemRoles || []).join(" "),
        }));
        result.push(...processedChunk);
      }

      return result;
    }, [data]);
    const toggleRowExpansion = (rowId) => {
      setExpandedRows((prev) => ({
        ...prev,
        [rowId]: !prev[rowId],
      }));
    };

    const columns = useMemo(() => [
      {
        accessorKey: "userName",
        header: "USER NAME",
        size: 100,
      },
      {
        id: "roleNames",
        accessorKey: "roleNames",
        header: "ROLE(s)",
        minSize: 150,
        maxSize: 2500,
        Cell: ({ row, cell, column }) => (
          <ExpandableCell
            value={cell.getValue()}
            isExpanded={expandedRows[row.id]}
            onToggle={() => toggleRowExpansion(row.id)}
            columnId={column.id}
          />
        ),
      },
      {
        id: "systemRoles",
        accessorKey: "roleNames",
        header: "SYSTEM ROLE(s)",
        minSize: 150,
        maxSize: 2500,
        Cell: ({ row, cell, column }) => (
          <ExpandableCell
            value={cell.getValue()}
            isExpanded={expandedRows[row.id]}
            onToggle={() => toggleRowExpansion(row.id)}
            columnId={column.id}
          />
        ),
      },
      // {
      //   accessorKey: "resources",
      //   header: "RESOURCES",
      //   size: 80,
      //   Cell: ({ row, cell }) => (
      //     <ExpandableCell
      //       value={cell.getValue()}
      //       isExpanded={expandedRows[row.id]}
      //       onToggle={() => toggleRowExpansion(row.id)}
      //     />
      //   ),
      // },
      {
        accessorKey: "firstName",
        header: "GIVEN NAME",
        size: 90,
        Cell: ({ cell }) => <div className="padding-lr-10">{cell.getValue()}</div>,
      },
      {
        accessorKey: "lastName",
        header: "FAMILY NAME",
        size: 90,
        Cell: ({ cell }) => <div className="padding-lr-10">{cell.getValue()}</div>,
      },
      {
        accessorKey: "organization",
        header: "ORGANIZATION",
        size: 150,
        Cell: ({ cell }) => <div className="padding-lr-10">{cell.getValue()}</div>,
      },
      {
        accessorKey: "activation",
        header: "STATUS",
        size: 70,
        Cell: ({ cell }) => {
          return cell.getValue() === 1 ? (
            <span className="status-active">Active</span>
          ) : (
            <span className="status-inactive">Inactive</span>
          );
        },
      },
      {
        accessorKey: "searchableRoles",
        header: "searchableRoles",
        size: 0,
      },
      { accessorKey: "searchableSystemRoles", header: "searchableSystemRoles", size: 0 },
    ]);
    const customTableOptions = {
      columns,
      data: processedData,

      renderTopToolbar: ({ table }) => (
        <Box className="flex_box-space">
          <Grid container className="UserListContainer">
            <Grid item xs={9}></Grid>
            <Grid item xs={2} className="global-search-textbox-wrapper">
              <MRT_GlobalFilterTextField table={table} />
            </Grid>
            {!isLoading && (
              <Grid item xs={1} className="download-btn-wrapper">
                <Button
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  className="download-option-btn-top report-btn"
                  onClick={() => setIsDownloadOpen(!isDownloadOpen)}
                >
                  Download
                </Button>
                {isDownloadOpen && (
                  <div className="download-option-btns">
                    <GenerateCsv
                      rawData={rawData}
                      data={data}
                      type="analytical"
                      reportNameAnalytical="RoleAssignmentReport-Analytical"
                    />
                    <GenerateCsv
                      rawData={rawData}
                      data={data}
                      type="standard"
                      reportNameStandard="RoleAssignmentReport-Standard"
                    />
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      ),
      enableExpanding: false,
      enablePagination: true,
      paginationDisplayMode: "pages",
      enableRowNumbers: false,
      muiPaginationProps: {
        className: "paginationClasses",
        shape: "rounded",
        variant: "outlined",
        rowsPerPageOptions: [5, 10, 25, 50],
        showFirstButton: false,
        showLastButton: false,
      },
      state: { isLoading },
      columnResizeDirection: "ltr",
      muiTablePaperProps: ({ table }) => ({
        style: {
          top: table.getState().isFullScreen ? 100 : 0,
        },
      }),

      muiSkeletonProps: {
        animation: "wave",
      },
      initialState: {
        showGlobalFilter: true,
        columnVisibility: {
          searchableRoles: false,
          searchableSystemRoles: false,
          systemRoles: false,
        },
      },
    };

    return <BaseTable setTotalData={setTotalData} customTableOptions={customTableOptions} />;
  }
};
export default RoleAssignmentTable;

RoleAssignmentTable.propTypes = {
  data: PropTypes.array,
  setTotalData: PropTypes.func,
  dataLoading: PropTypes.bool,
  rawData: PropTypes.array,
  cell: PropTypes.object,
  row: PropTypes.object,
  column: PropTypes.object,
};

ExpandableCell.propTypes = {
  value: PropTypes.array,
  isExpanded: PropTypes.bool,
  onToggle: PropTypes.func,
  row: PropTypes.object,
};
