import React, { createContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useConfig } from "./ConfigContext";
import handleApiError from "../helpers/HandleapiError/HandleapiError";

export const TenantServiceContext = createContext({
  tenantSvc: null,
  tenantSvcError: null,
  tenantSvcReady: false,
});

export const useTenant = () => React.useContext(TenantServiceContext);

const NotFoundPage = ({ errorMessage, error }) => (
  <div style={{ margin: "10px" }}>
    <h1>
      {error?.status} {error?.statusText}
    </h1>
    <p>{errorMessage}</p>
  </div>
);
const TenantProvider = ({ children, tenantID }) => {
  const [tenantSvcError, setTenantSvcError] = useState("");
  const [tenantSvcReady, setTenantSvcReady] = useState(false);
  const [error, setError] = useState(null);
  const [tenantSvc, setTenantSvc] = useState(null);
  const { config } = useConfig();

  const fetchTenantData = async () => {
    try {
      if (config && tenantID) {
        const tenant_url = `${tenantID == "psa" ? config?.tenantSvcPsa : config?.tenantSvc}/${tenantID}`;
        const response = await axios.get(tenant_url);
        setTenantSvc(response.data);
        setTenantSvcReady(true);
      }
    } catch (error) {
      setError(error);
      const errormessage = handleApiError(error);
      setTenantSvcError(errormessage);
      setTenantSvcReady(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchTenantData();
    };

    if (!tenantSvcReady) {
      fetchData();
    }
  }, [config, tenantSvcReady]);

  const memoedTenantService = useMemo(
    () => ({
      tenantSvc,
      tenantSvcError,
      tenantSvcReady,
    }),
    [tenantSvcReady, tenantSvcError]
  );
  return (
    <TenantServiceContext.Provider value={memoedTenantService}>
      {tenantSvcError ? (
        window.history && window.history.length > 3 ? (
          <>{window.history.go(-1)}</>
        ) : (
          <NotFoundPage errorMessage={tenantSvcError} error={error} />
        )
      ) : (
        children
      )}
    </TenantServiceContext.Provider>
  );
};

TenantProvider.propTypes = {
  children: PropTypes.node,
  tenantID: PropTypes.string,
};

NotFoundPage.propTypes = {
  errorMessage: PropTypes.string,
  error: PropTypes.object,
};
export default TenantProvider;
