import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const CopyableCell = ({ cell, row, handleCopy }) => {
  return (
    <Tooltip title="Click to copy row data">
      <span style={{ cursor: "copy", display: "flex", alignItems: "center" }} onClick={() => handleCopy(row)}>
        <div className="padding-lr-10">{cell.getValue()}</div>
      </span>
    </Tooltip>
  );
};

export default CopyableCell;
CopyableCell.propTypes = {
  cell: PropTypes.func.object,
  row: PropTypes.object,
  handleCopy: PropTypes.function,
};
