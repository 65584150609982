import { useParams } from "react-router";
import CredMgmtSetPassword from "../../components/CredMgmtSetPassword/CredMgmtSetPassword";

function CredMgmtSetPasswordPage() {
  const { tenant } = useParams();
  const theme = `${tenant}-theme`;

  return (
    <>
      <div className={theme} data-testid="theme-name">
        <CredMgmtSetPassword />
      </div>
    </>
  );
}

export default CredMgmtSetPasswordPage;
