import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import PropTypes from "prop-types";
const ExpandableCell = ({ value, isExpanded, onToggle }) => {
  if (!value || value?.length === 0) return null;
  return (
    <div
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      {isExpanded ? (
        <>
          {value.map((item, index) => (
            <div
              key={index}
              className={value?.length == 1 ? "single-role" : index > 0 ? "multiple-roles" : ""}
              style={{
                maxWidth: "calc(100%)",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {index === 0 && value?.length > 1 && (
                <span
                  onClick={onToggle}
                  style={{ cursor: "pointer", verticalAlign: "text-top", display: "inline-flex" }}
                >
                  <ArrowDropDownSharpIcon fontSize="medium" className="ArrowDropDownSharpIcon" />
                </span>
              )}
              <span className="role-value">{item}</span>
            </div>
          ))}
        </>
      ) : (
        <div
          className={value?.length == 1 && "single-role"}
          style={{
            maxWidth: "calc(100%)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {value?.length > 1 && (
            <span onClick={onToggle} style={{ cursor: "pointer", verticalAlign: "text-top", display: "inline-flex" }}>
              <ArrowRightSharpIcon
                fontSize="medium"
                data-testid="ArrowRightSharpIcon"
                className="ArrowRightSharpIcon"
              />
            </span>
          )}
          {value[0]}
        </div>
      )}
    </div>
  );
};
export default ExpandableCell;

ExpandableCell.propTypes = {
  value: PropTypes.array,
  isExpanded: PropTypes.bool,
  onToggle: PropTypes.func,
};
