let _accountActivationSuccess = `Your account is active.
\nPlease note that you will have to install an authenticator app such as Google Authenticator or Free OTP in your smart phone prior to clicking the link. The authenticator app can be downloaded from your respective app store.
\nPlease click the following link to login`;

const _invalidTokenMessage = "Token is no longer valid.";
const _validationError = "There were validation errors.";
const _unknownError = "Oops! Something went wrong. We could not process your request right now.";
const _forgotPasswordSuccessMessage =
  "If your email address is registered with us, you will receive an email shortly with instructions on how to reset your password.";

export {
  _accountActivationSuccess,
  _invalidTokenMessage,
  _validationError,
  _unknownError,
  _forgotPasswordSuccessMessage,
};
