import Skeleton from "@mui/material/Skeleton";
import { Box, Stack } from "@mui/material";
const ListUsersTableSkeleton = () => {
  return (
    <Box
      sx={{
        height: "max-content",
      }}
    >
      {[...Array(10)].map(() => {
        return (
          <Stack direction="row" spacing={1} sx={{ my: 2, mx: 1 }} key={Math.random()}>
            <Skeleton data-testid="circular-skeleton" animation="wave" variant="circular" width={40} height={40} />
            <Skeleton data-testid="rectangular-skeleton" variant="rectangular" width={200} height={30} />
            <Skeleton data-testid="rectangular-skeleton" variant="rectangular" width={200} height={30} />
            <Skeleton data-testid="rectangular-skeleton" variant="rectangular" width={200} height={30} />
            <Skeleton data-testid="rectangular-skeleton" variant="rectangular" width={200} height={30} />
            <Skeleton data-testid="rectangular-skeleton" variant="rectangular" width={200} height={30} />
            <Skeleton data-testid="rectangular-skeleton" variant="rectangular" width={200} height={30} />
            <Skeleton data-testid="rectangular-skeleton" variant="rectangular" width={100} height={30} />
            <Skeleton data-testid="rectangular-skeleton" variant="rectangular" width={200} height={30} />
          </Stack>
        );
      })}
    </Box>
  );
};
export default ListUsersTableSkeleton;
