import { Profile } from "../../components/Profile";
import { useParams } from "react-router-dom";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import PropTypes from "prop-types";
import "./ProfilePage.css";
import { useConfig } from "../../contexts/ConfigContext";
import SecureComponent from "../../components/SecApp/SecureComponent";

function ProfilePage({ allowedRoles }) {
  const { tenant } = useParams();
  const { config } = useConfig();
  const theme = `${tenant}-theme`;
  return (
    <>
      <SecureComponent allowedRoles={allowedRoles}>
        <div className={theme} data-testid="theme-name">
          <div className="profilePageHeader" data-testid="profile-pageCustomHeader">
            <CustomAppBar config={config} />
          </div>
          <Profile className="profilePage" />
        </div>
      </SecureComponent>
    </>
  );
}
ProfilePage.propTypes = {
  config: PropTypes.object,
  allowedRoles: PropTypes.array,
};
export default ProfilePage;
