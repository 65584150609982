import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";

function UserEditedSuccessPopUp() {
  const { successUserNameEdit } = useContext(DataContext);

  return <div className="success-msg">{`${successUserNameEdit} updated successfully`}</div>;
}

export default UserEditedSuccessPopUp;
