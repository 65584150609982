import { useState } from "react";

function HandleMoreInfoState() {
  const [isMoreInfoVisible, setIsMoreInfoVisible] = useState(false);
  const toggleMoreInfo = () => {
    setIsMoreInfoVisible((prev) => !prev);
  };
  return { isMoreInfoVisible, toggleMoreInfo };
}

export default HandleMoreInfoState;
