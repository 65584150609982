import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";

function UserProfileSuccessPopUp() {
  const { successUserNameProfile } = useContext(DataContext);

  return <div className="success-msg">{`${successUserNameProfile} saved successfully`}</div>;
}

export default UserProfileSuccessPopUp;
