const FormatDateTimeCSV = (datetimeStr) => {
  if (!datetimeStr) return "";
  try {
    const date = new Date(datetimeStr);
    if (isNaN(date.getTime())) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDay()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

export const extractDateAndTime = (dateString) => {
  if (!dateString) return { formattedDate: "", formattedTime: "" };
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return { formattedDate: "", formattedTime: "" };

    const optionsDate = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = date.toLocaleDateString(undefined, optionsDate);

    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC",
    };
    const formattedTime = date.toLocaleTimeString(undefined, optionsTime);

    return {
      formattedDate,
      formattedTime,
    };
  } catch (error) {
    console.error("Error formatting date:", error);
    return { formattedDate: "", formattedTime: "" };
  }
};
export function FormatDateTimeUI(dateString) {
  if (!dateString) return "";

  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
}
export function FormatDateTimeReport(dateString) {
  if (!dateString) return "";

  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate().toString().padStart(2, "0");
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
}

export const renameKey = (data, oldKey, newKey) => {
  if (!data || !oldKey || !newKey) return "";
  return data?.map((item) => {
    const { [oldKey]: oldValue, ...rest } = item;
    return {
      ...rest,
      [newKey]: oldValue,
    };
  });
};
export default FormatDateTimeCSV;
