import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { useTenant } from "../../contexts/TenantService";

export const OrganizationServiceProvider = () => {
  const { authService } = useAuth();
  const token = authService?.getAccessToken();
  const { tenantSvc } = useTenant();
  const tenantIdentifier = tenantSvc?.tenantId;
  const getOrganization = async (resource) => {
    if (tenantSvc) {
      const response = await axios.get(`${tenantSvc?.apiUrl}/${tenantIdentifier}/${resource}`, {
        headers: { Authorization: `bearer ${token}` },
      });
      return response;
    }
  };

  const getOrganizationByID = async (resource, orgID) => {
    const response = await axios.get(`${tenantSvc?.apiUrl}/${tenantIdentifier}/${resource}/${orgID}`, {
      headers: { Authorization: `bearer ${token}` },
    });
    return response;
  };
  return { getOrganization, getOrganizationByID };
};

export default OrganizationServiceProvider;
