import PropTypes from "prop-types";
import { useEffect, useState, useMemo } from "react";
import { treeUtil } from "../TreeUtils/TreeUtils";
import DropdownTreeSelect from "react-dropdown-tree-select";
function OrganizationTreeSturctureMeta({
  OrgList,
  mode,
  baseOrganizationId,
  setOrgValue,
  setSelectedRoles,
  tenant,
  orgTitle,
}) {
  const [organizationTree, setOrganizationTree] = useState({});
  const [placeholderTxt, setPlaceholderTxt] = useState("");
  useEffect(() => {
    if (!OrgList?.allOrgs?.data) {
      return;
    }
    let treeNode = treeUtil.createTreeForDropdown(OrgList?.allOrgs?.data, [], undefined, tenant, orgTitle);

    if (treeNode.dropdownTreeNodesHash[baseOrganizationId] == undefined) {
      setPlaceholderTxt("Select");
    } else {
      setPlaceholderTxt("");
      if (baseOrganizationId && treeNode) {
        treeNode.dropdownTreeNodesHash[baseOrganizationId].checked = true;
      }
    }
    setOrganizationTree(treeNode.dropdownTree);
  }, [OrgList?.allOrgs?.data, baseOrganizationId]);

  const DropDownTreeSelect = useMemo(() => {
    switch (mode) {
      case "edit":
        return (
          organizationTree && (
            <DropdownTreeSelect disabled data={organizationTree} mode="radioSelect" className="CreateNode" />
          )
        );
      case "create":
        return (
          organizationTree && (
            <DropdownTreeSelect
              data={organizationTree}
              onChange={(currentNode) => {
                setOrgValue(currentNode.id);
                setSelectedRoles([]);
              }}
              mode="radioSelect"
              className="CreateNode"
              texts={{ placeholder: placeholderTxt !== "" && placeholderTxt }}
            />
          )
        );
    }
  }, [organizationTree, placeholderTxt]);

  return <div data-testid="organizationTreeInput">{DropDownTreeSelect}</div>;
}
OrganizationTreeSturctureMeta.propTypes = {
  OrgList: PropTypes.object,
  mode: PropTypes.bool,
  baseOrganizationId: PropTypes.string,
  setOrgValue: PropTypes.func,
  setSelectedRoles: PropTypes.func,
  tenant: PropTypes.string,
  orgTitle: PropTypes.string,
};
export default OrganizationTreeSturctureMeta;
