import axios from "axios";

export function getUserOnboardingDetails(onBoardingDetailsEndPtUrl, userID, token) {
  return axios.get(onBoardingDetailsEndPtUrl + userID, {
    headers: { Authorization: `bearer ${token}` },
  });
}

export function resetOTP(userID, reset2FAURL, token) {
  return axios.post(
    reset2FAURL + userID,
    {
      id: userID,
    },
    { headers: { Authorization: `bearer ${token}` } }
  );
}
