import React, { createContext, useMemo, useState, useEffect } from "react";
import propTypes from "prop-types";

const ConfigContext = createContext({
  config: null,
  configError: null,
});

const ConfigProvider = (props) => {
  const [config, setConfig] = useState(null);
  const [configError, setConfigError] = useState(null);
  const [configReady, setConfigReady] = useState(false);
  useEffect(() => {
    if (!configReady) {
      fetch("/ciam/v1/static/config.json")
        .then((response) => response.json())
        .then((data) => {
          setConfig(data);
          setConfigReady(true);
        })
        .catch((error) => {
          setConfigError(error);
        });
    }
  }, [configReady]);

  const memoedConfig = useMemo(
    () => ({
      config,
      configError,
      configReady,
    }),
    [configReady]
  );

  return <ConfigContext.Provider value={memoedConfig}>{props.children}</ConfigContext.Provider>;
};

ConfigProvider.propTypes = {
  children: propTypes.node,
};

export default ConfigProvider;
export const useConfig = () => React.useContext(ConfigContext);
