import UserListTable from "../UserListTable/UserListTable";
import "./ListUser.css";

function ListUser() {
  return (
    <>
      <UserListTable data-testid="user-list-table" />
    </>
  );
}

export default ListUser;
