import { Card, Grid, Box, Skeleton, Stack } from "@mui/material";
export default function UserProfileSkeleton() {
  return (
    <Box sx={{ mt: "3%" }}>
      <Grid container spacing={1} sx={{ mb: "1%" }}>
        <Grid item xs={3}>
          <Stack direction="row">
            <Skeleton variant="rectangular" width={20} height={20} sx={{ m: "10px 20px" }} />
            <Skeleton variant="rectangular" width={80} height={20} sx={{ m: "10px 20px" }} />
          </Stack>
        </Grid>
      </Grid>
      <Card className="userProfileCard" data-testid="user-profile-card">
        <Box sx={{ flexGrow: 1, padding: "20px" }}>
          <Grid container spacing={2} sx={{ padding: "20px" }}>
            <Skeleton variant="circular" width={210} height={210} data-testid="avatar-skeleton" />

            <Grid item xs={10} sx={{ mt: "00px " }}>
              <Grid container spacing={2}>
                {[...Array(4)].map((_, index) => (
                  <Grid item xs={3} key={index}>
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={10}
                      sx={{ m: "10px 20px" }}
                      data-testid={`information-skeleton-item-${index}`}
                    />
                    <Skeleton variant="rounded" width={200} height={40} sx={{ m: "10px 20px" }} />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2} sx={{ mt: "20px " }}>
                {[...Array(3)].map((_, index) => (
                  <Grid item xs={3} key={index}>
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={10}
                      sx={{ m: "10px 20px" }}
                      data-testid={`information-skeleton-item-${index + 4}`} // Adjust index for uniqueness
                    />
                    <Skeleton variant="rounded" width={200} height={40} sx={{ m: "10px 20px" }} />
                  </Grid>
                ))}

                <Grid item xs={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box></Box>
        </Box>
      </Card>
      <Card className="userProfileCard" data-testid="user-profile-card">
        <Stack direction="row">
          <Skeleton variant="rectangular" width={1200} height={30} sx={{ m: "30px 20px" }} />
        </Stack>
      </Card>
      <Card className="userProfileCard" data-testid="user-profile-card">
        <Stack direction="row">
          <Skeleton variant="rectangular" width={1200} height={30} sx={{ m: "30px 20px" }} />
        </Stack>
      </Card>
      <Card className="userProfileCard" data-testid="user-profile-card">
        <Stack direction="row">
          <Skeleton variant="rectangular" width={1200} height={30} sx={{ m: "30px 20px" }} />
        </Stack>
      </Card>
      <Card className="userProfileCard" data-testid="user-profile-card">
        <Stack direction="row">
          <Skeleton variant="rectangular" width={1200} height={30} sx={{ m: "30px 20px" }} />
        </Stack>
      </Card>
    </Box>
  );
}
