function TransformJsonDataUtil(users) {
  let transformedData = [];

  users.forEach((user) => {
    let { userName, roleName, systemRole, resource, ...rest } = user;
    let existingUser = transformedData.find((item) => item.userName === userName);

    if (existingUser) {
      existingUser.roleNames.push(roleName);
      existingUser.systemRoles.push(systemRole);
      existingUser.resources.push(resource);
    } else {
      transformedData.push({
        userName: userName,
        ...rest,
        roleNames: [roleName],
        systemRoles: [systemRole],
        resources: [resource],
      });
    }
  });

  return transformedData;
}

export default TransformJsonDataUtil;
