import { CircularProgress } from "@mui/material";
import "./LoadSpinner.css";

const LoadingSpinner = () => {
  const tenantID = sessionStorage.getItem("tenantID");
  return (
    <CircularProgress
      sx={{ display: "flex", justifyContent: "center", margin: "0 auto" }}
      className={tenantID == "psa" ? "psaSpinColor" : "wlSpincolor"}
    />
  );
};

export const CenterLoadingSpinner = () => {
  const tenantID = sessionStorage.getItem("tenantID");
  return (
    <div style={{ margin: "21% auto", height: "40%" }}>
      <CircularProgress
        sx={{ display: "flex", justifyContent: "center", margin: "0 auto" }}
        className={tenantID === "psa" ? "psaSpinColor" : "wlSpincolor"}
      />
    </div>
  );
};

export default LoadingSpinner;
