import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "./i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import ConfigProvider from "./contexts/ConfigContext.js";
import { AuthProvider } from "./contexts/AuthContext.js";
import TenantProvider from "./contexts/TenantService.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

const regex = /\/ciam\/v1\/([^/?#]+)/;
const tenant_params = window.location.href.match(regex);
const tenantID = tenant_params[1];
sessionStorage.setItem("tenantID", tenantID);
root.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider>
      <TenantProvider tenantID={tenantID}>
        <AuthProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </AuthProvider>
      </TenantProvider>
    </ConfigProvider>
  </QueryClientProvider>
);
