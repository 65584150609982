const FaviconLoader = (url) => {
  if (typeof window === "undefined") return null;

  const link = window.document.querySelector("link[rel*='icon']") || window.document.createElement("link");
  link.rel = "icon";
  const faviconUrl = new URL(String(url), window.location.origin);
  link.href = faviconUrl.href;
  link.type = "image/png";

  const appendLinkToHead = () => {
    window.document.getElementsByTagName("head")[0].appendChild(link);
  };

  if (window.document.readyState === "complete") {
    appendLinkToHead();
  } else {
    window.document.addEventListener("DOMContentLoaded", appendLinkToHead);
  }

  return null;
};

export default FaviconLoader;
