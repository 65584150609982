import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import SecureComponent from "../../components/SecApp/SecureComponent";
import { useConfig } from "../../contexts/ConfigContext";
import RoleAssignmentReport from "../../components/RoleAssignmentReport/RoleAssignmentReport";

function RoleAssignmentReportPage({ allowedRoles }) {
  const { config } = useConfig();
  const { tenant } = useParams();
  const theme = `${tenant}-theme`;
  return (
    <>
      <SecureComponent allowedRoles={allowedRoles}>
        <div className={theme} data-testid="theme-name">
          <CustomAppBar className="app-bar " config={config} />
          <div data-testid="role-assignment-report-page">
            <RoleAssignmentReport config={config} />
          </div>
        </div>
      </SecureComponent>
    </>
  );
}
RoleAssignmentReportPage.propTypes = {
  config: PropTypes.object,
  allowedRoles: PropTypes.array,
};
export default RoleAssignmentReportPage;
