import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Box, InputAdornment, ListItem, TextField } from "@mui/material";

export const CustomParentBox = styled(Box)(() => ({
  flexGrow: 1,
  height: "100vh",
  width: "100%",

  fontSize: "13px",
  p: 0,
  m: 0,
}));
export const LeftBannerBoxParent = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  [theme.breakpoints.down("xs")]: {
    display: "none",
  },
  justifyContent: "center",
  alignItems: "center",
}));
export const RightBannerBoxParent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("xs")]: {
    margin: "0 auto",
  },
}));
export const RightLogoBox = styled(Box)(({ theme }) => ({
  width: 80,
  height: "auto",
  margin: "-10% 0 10px 0",
  [theme.breakpoints.down("xs")]: {
    marginLeft: "-6%",
  },
}));
export const InputParent = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  marginTop: "10px",
}));
export const ButtonLoginParent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: "10px",
  height: "30px",
  alignItems: "center",

  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
export const CustomListItem = styled(ListItem)({
  padding: 0,
  color: "#414141",
  fontSize: "12px",
});
export const IconTextField = ({ iconStart, iconEnd, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: iconStart ? <InputAdornment position="start">{iconStart}</InputAdornment> : null,
        endAdornment: iconEnd ? <InputAdornment position="end">{iconEnd}</InputAdornment> : null,
        style: {
          height: "35px",
          width: "260px",
          border: "1px solid #DCE3F3",
          backgroundColor: "#fff",
          fontSize: "13px",
        },
      }}
      className="cred-mgmt-input-field"
    />
  );
};

IconTextField.propTypes = {
  iconStart: PropTypes.func,
  iconEnd: PropTypes.func,
};
