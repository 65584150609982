const handlebars = require("handlebars");
import { Box, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import inputHandler from "../../helpers/InputHandler/InputHandler";
import { useTenant } from "../../contexts/TenantService";

const transformName = (orgPrefix, fields, org, userInput) => {
  if (org.usernamePolicy) {
    const template = handlebars.compile(org.usernamePolicy.autoFillPolicy.userNamePattern);
    let context = {
      prefix: orgPrefix,
    };

    for (const info of org.usernamePolicy.autoFillPolicy.autoFillInfo) {
      const fieldValue = fields[info.field] || "";
      const value = fieldValue
        ? info.numberOfChars !== -1
          ? inputHandler.normalizeUsername(fieldValue).substring(0, info.numberOfChars)
          : inputHandler.normalizeUsername(fieldValue)
        : "";
      context[info.label] = value;
    }

    const newUname = template(context) + (userInput ?? "");

    return newUname.toLowerCase();
  } else {
    const newfname = fields.familyName ? inputHandler.normalizeUsername(fields.familyName).substring(0, 4) : "";
    const newgname = fields.givenName ? inputHandler.normalizeUsername(fields.givenName).substring(0, 3) : "";
    const newUname =
      newfname || newgname ? `${orgPrefix}_${newfname}${newgname}${userInput ?? ""}` : `${userInput ?? ""}`;

    return newUname?.toLowerCase();
  }
};

const InputUserName = ({ org, fields, grabit, field, meta }) => {
  const [userInput, setUserInput] = useState("");
  const { tenantSvc } = useTenant();

  useEffect(() => {
    if (org?.usernamePolicy) {
      let isEmpty = true;

      for (const info of org?.usernamePolicy?.autoFillPolicy?.autoFillInfo || []) {
        const fieldValue = fields[info.field] || "";
        if (fieldValue.trim() !== "") {
          isEmpty = false;
          break;
        }
      }

      if (!isEmpty) {
        const newTransformedName = org && transformName(org?.prefix, fields, org, "");
        setUserInput(newTransformedName);
        grabit(newTransformedName);
      } else {
        setUserInput("");
        grabit("");
      }
    } else {
      const newTransformedName = org && transformName(org?.prefix, fields, org, "");
      setUserInput(newTransformedName);
      grabit(newTransformedName);
    }
  }, [fields, org?.prefix]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {tenantSvc?.tenantId == "psa" && org?.prefix && org?.name !== "worldline" ? (
          <TextField
            source="prefix"
            variant="outlined"
            size="small"
            disabled
            label=""
            sx={{
              width: "110px",
              borderRightStyle: "none",
              "& .MuiInputBase-root": { borderRadius: "4px 0 0 4px" },
            }}
            InputProps={{
              classes: {
                notchedOutline: "right",
              },
            }}
            value={tenantSvc?.tenantUsernamePrefix}
          />
        ) : (
          ""
        )}
        <TextField
          name="name"
          label=""
          placeholder="Enter"
          variant="outlined"
          size="small"
          {...field}
          value={userInput}
          onChange={(e) => {
            field.onChange(e);
            setUserInput(e.target.value);
            grabit(e.target.value);
          }}
          onBlur={field.onBlur}
          sx={{ height: 34 }}
          className={
            tenantSvc?.tenantId == "psa" && org?.prefix && org?.name !== "worldline"
              ? "orgCreateUserPartialRounded"
              : "orgCreateUserRounded"
          }
        />
      </Box>
      <Box>{meta.touched && meta.error && <div className="error-msg">{meta.error}</div>}</Box>
    </>
  );
};

InputUserName.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.object,
  field: PropTypes.object,
  grabit: PropTypes.func,
  org: PropTypes.object,
};

export default InputUserName;
