import { useAuth } from "../../contexts/AuthContext";
import { CenterLoadingSpinner } from "../Spinners/LoadSpinner";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const SecureComponent = ({ children, allowedRoles }) => {
  const { authService, setAuthenticated, sessionTracker } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await authService.login();
        await authService.updateToken();
        setAuthenticated(true);
        setIsAuthenticated(true);
      } catch (error) {
        setAuthenticated(false);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();

    // sessionTracker.start();

    // return () => {
    //   sessionTracker.cleanUp();
    // };
  }, [authService, setAuthenticated, sessionTracker]);

  if (!authService || isAuthenticated === null) {
    return <CenterLoadingSpinner />;
  }

  if (isAuthenticated && authService.authorize(allowedRoles)) {
    return children;
  }

  return <CenterLoadingSpinner />;
};

SecureComponent.propTypes = {
  children: PropTypes.node,
  allowedRoles: PropTypes.array,
};

export default SecureComponent;
