class InputHandler {
  constructor() {}

  normalizeUsername = (username) => {
    return username
      .toLowerCase()
      .replace(/[åäæ]/g, "a")
      .replace(/[öø]/g, "o")
      .replace(/[ß]/g, "ss")
      .replace(/[ü]/g, "u")
      .replace(/[^a-z_0-9]/g, "");
  };
}

const inputHandler = new InputHandler();

export default inputHandler;
