const passwordPolicyTexts = {
  minLength: "The password must have at least {minLength} characters",
  uppercase: "Upper case letters (A-Z)",
  lowercase: "Lower case letters (a-z)",
  digit: "Numbers (0-9)",
  specialCharacter: "At least one special character from the following",
  specialCharList: "!#$&*+,-.?%@^_~=",
  lastPwdUsed: "The last 5 passwords used must not be repeated and will not be accepted by the system.",
};
export default passwordPolicyTexts;
