const ErrorMessages = {
  BAD_REQUEST: "Bad request: The request was malformed.",
  UNAUTHORIZED: "Unauthorized: Please log in or provide a valid token to continue.",
  FORBIDDEN: "Forbidden: You don't have permission to access this resource.",
  NOT_FOUND: "Not found: The requested resource could not be found.",
  TOKEN_EXPIRED: "Session Expired: Please log in again to continue.",
  INTERNAL_SERVER_ERROR: "An unexpected error occurred. Please try again later or contact support.",
  ERR_NETWORK: "Service unavailable: Please try again later.",
  ERR_UNKNOWN: "An unexpected error occurred.",
};

const statusCodeToMessage = {
  400: ErrorMessages.BAD_REQUEST,
  401: ErrorMessages.UNAUTHORIZED,
  403: ErrorMessages.FORBIDDEN,
  404: ErrorMessages.NOT_FOUND,
  500: ErrorMessages.INTERNAL_SERVER_ERROR,
  503: ErrorMessages.ERR_NETWORK,
};

const handleValidationErrors = (errorDescription, validationErrors) => (
  <>
    {errorDescription && <span>{errorDescription}</span>}
    {validationErrors?.length > 0 && (
      <ul>
        {validationErrors?.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    )}
  </>
);

const handleErrorWithCode = (code, message, details) => {
  switch (code) {
    case "invalid":
      if (details?.errors) {
        return details?.errors.length > 1 ? (
          <div className="error-container">
            <div className="error-message-header">
              <b>Validation failed for the following fields:</b>
            </div>
            {details.errors.map((err, index) => (
              <div key={index} className="error-message-items">
                {err.issues.join(", ")}
              </div>
            ))}
          </div>
        ) : (
          <div>
            <b>Validation failed : </b>
            {details.errors.map((err) => err.issues.join(", ")).join("; ")}
          </div>
        );
      }
      return "Error: Invalid input data.";
    case "internalError":
      return ErrorMessages.INTERNAL_SERVER_ERROR;
    case "unauthorized":
      return ErrorMessages.UNAUTHORIZED;
    case "expired":
      return ErrorMessages.TOKEN_EXPIRED;
    case "notFound":
      return ErrorMessages.NOT_FOUND;
    default:
      return `Error: ${message || "An unknown error occurred"}`;
  }
};

const handleApiError = (error) => {
  if (error?.response) {
    const { status, data } = error.response;

    if (data?.error_description) {
      const { error_description = "", error_validations = [] } = data;
      const validationErrors = error_validations?.map((error) => error?.message);

      if (status === 400 && !error_description && validationErrors?.length === 0) {
        return ErrorMessages.BAD_REQUEST;
      }

      return handleValidationErrors(error_description, validationErrors);
    }

    if (data?.error) {
      return handleErrorWithCode(data.error.code, data.error.message, data.error.details);
    }

    return statusCodeToMessage[status] || ErrorMessages.ERR_UNKNOWN;
  } else if (error?.request) {
    return "Network error: Unable to reach the server. Please try again later.";
  } else {
    return `Unexpected error: ${error?.message || "An error occurred."}`;
  }
};

export default handleApiError;
