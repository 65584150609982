class SessionTracker {
  constructor({ timeout, onTokenExpired }) {
    this.events = ["mousemove", "scroll", "keydown"];
    this.timeout = timeout;
    this.onTokenExpired = onTokenExpired;

    this.eventHandler = this.onUserAction.bind(this);
    this.tracker();
  }

  onUserAction() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem("_sessionExpireTime", Date.now() + this.timeout * 1000);
      this.onTokenExpired();
    }, 300);
  }

  start() {
    this.onUserAction();

    this.interval = setInterval(() => {
      const expireTime = parseInt(localStorage.getItem("_sessionExpireTime") || 0, 10);
      if (expireTime < Date.now() && this.onTokenExpired) {
        this.onTokenExpired();
        this.cleanUp();
      }
    }, 1000);
  }

  tracker() {
    this.events.forEach((event) => window.addEventListener(event, this.eventHandler));
  }

  cleanUp() {
    localStorage.removeItem("_sessionExpireTime");

    clearInterval(this.interval);
    this.events.forEach((event) => window.removeEventListener(event, this.eventHandler));
  }
}

export default SessionTracker;
