import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    --psa_theme_dark:#09f;
    --psa_theme_background: #fafcfe;
    --psa_filter_expand: #0099ff;
    --psa_table_border: #d2edff;
    --psa_table_header_background: #d2edff;
    --psa_scrollbar-thumb:#DCE3F3;
    --psa_admin-font-color:#108ee9;
    --psa-user-name-link-color:#1976d2;
    --psa-pagination-border:rgb(0, 153, 255);

     --default_table_header_background : #41B4D2;
     --default_btn_color: #46BEAA;
     --default_bg_color:#FAFCFE;
     --default_table_header-bg:#277777;
     --default-user-name-link-color:#46BEAA; 
     --default-pagination-border: #46beaa;
  }
`;

export default GlobalStyles;
