import { SecApp } from "./components/SecApp";
import { ThemeProvider } from "@mui/material";
import CustomTheme from "./styles/CustomTheme";
import GlobalStyles from "./styles/GlobalStyle";
import { DataContext } from "./contexts/DataContext";

import "./App.css";
import { useConfig } from "./contexts/ConfigContext";
import { CenterLoadingSpinner } from "./components/Spinners/LoadSpinner";
import { useState } from "react";
import FaviconLoader from "./helpers/FaviconLoader/FaviconLoader";
import { useTenant } from "./contexts/TenantService";

function App() {
  const { configReady } = useConfig();
  const { tenantSvc } = useTenant();
  const [successUserName, setSuccessUserName] = useState("");
  const [successUserNameEdit, setSuccessUserNameEdit] = useState("");
  const [successUserNameProfile, setSuccessUserNameProfile] = useState("");
  if (!configReady || !tenantSvc) {
    return <CenterLoadingSpinner />;
  }
  const bodyElement = document.body;
  bodyElement.className = `${tenantSvc?.tenantId} worldline`;

  FaviconLoader(`${process.env.PUBLIC_URL}/static/images/${tenantSvc.tenantId}/favicon.png`);

  return (
    <DataContext.Provider
      value={{
        successUserName,
        setSuccessUserName,
        successUserNameEdit,
        setSuccessUserNameEdit,
        successUserNameProfile,
        setSuccessUserNameProfile,
      }}
    >
      <ThemeProvider theme={CustomTheme}>
        <GlobalStyles />
        <SecApp />
      </ThemeProvider>
    </DataContext.Provider>
  );
}
export default App;
