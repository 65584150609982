import { DashBoard } from "../../components/DashBoard";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import SecureComponent from "../../components/SecApp/SecureComponent";
import { useConfig } from "../../contexts/ConfigContext";
function DashBoardPage({ allowedRoles }) {
  const { config } = useConfig();
  const { tenant } = useParams();
  const theme = `${tenant}-theme`;
  const defaultclass = "wl-parent";
  const dynamicClassName = `${defaultclass} ${theme}`;
  return (
    <>
      <SecureComponent allowedRoles={allowedRoles}>
        <div className={dynamicClassName} data-testid="theme-name">
          <CustomAppBar className="app-bar " config={config} />
          <DashBoard data-testid="tenant-context-provider" />
        </div>
      </SecureComponent>
    </>
  );
}
DashBoardPage.propTypes = {
  config: PropTypes.object,
  allowedRoles: PropTypes.array,
};
export default DashBoardPage;
