import BaseReport from "../BaseReport/BaseReport";
import RoleAssignmentTable from "../RoleAssignmentTable/RoleAssignmentTable";
import { RoleServiceProvider } from "../../services";
import "../BaseReport/BaseReport.css";
import { useEffect, useState } from "react";
function RoleAssignmentReport() {
  const [callDataSvc, setCallDataSvc] = useState(false);
  const roleService = RoleServiceProvider();

  const getRoleAssignmentDetails = async (roleService) => {
    return roleService.getRoleAssignmentDetails();
  };
  useEffect(() => {
    setCallDataSvc(true);
  }, []);

  return (
    <BaseReport
      title="Role Assignment"
      getDataService={getRoleAssignmentDetails}
      tableComponent={RoleAssignmentTable}
      roleService={roleService}
      callDataSvc={callDataSvc}
      setCallDataSvc={setCallDataSvc}
      classname="report-table-wrapper"
      transformData={true}
    />
  );
}

export default RoleAssignmentReport;
