import Skeleton from "@mui/material/Skeleton";
import { Stack } from "@mui/material";

const ProfileInfoSkeleton = () => {
  return (
    <Stack direction="row" spacing={1}>
      <Skeleton variant="rectangular" width={150} height={40} data-testid="rectangular-skeleton" />
      <Skeleton animation="wave" variant="circular" width={40} height={40} data-testid="circular-skeleton" />
    </Stack>
  );
};

export default ProfileInfoSkeleton;
