import { Box, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { ListUsersTableSkeleton } from "../ListUsersTableSkeleton";

const ListUsersBodySkeleton = () => {
  return (
    <Box sx={{ mt: "40px", padding: "20px" }}>
      <Grid container spacing={2} sx={{ mb: "50px" }}>
        <Grid item xs={2}>
          <Skeleton data-testid="text-skeleton" variant="text" sx={{ fontSize: "12px" }} />
        </Grid>
        <Grid item xs={7}></Grid>
        <Grid item xs={2}>
          <Skeleton data-testid="rounded-skeleton-1" variant="rounded" width={210} height={40} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton data-testid="rounded-skeleton-2" variant="rounded" width={90} height={40} />
        </Grid>
      </Grid>
      <ListUsersTableSkeleton />
    </Box>
  );
};
export default ListUsersBodySkeleton;
