import { useParams } from "react-router-dom";
import "./UserListPage.css";
import { ListUser } from "../../components/ListUser";

import PropTypes from "prop-types";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import { useConfig } from "../../contexts/ConfigContext";
import SecureComponent from "../../components/SecApp/SecureComponent";

function UserListPage({ allowedRoles }) {
  const { config } = useConfig();
  const { tenant } = useParams();
  const theme = `${tenant}-theme`;

  return (
    <>
      <SecureComponent allowedRoles={allowedRoles}>
        <div className={theme} data-testid="theme-name">
          <CustomAppBar className="app-bar " config={config} />
          <ListUser config={config} />
        </div>
      </SecureComponent>
    </>
  );
}
UserListPage.propTypes = {
  config: PropTypes.object,
  allowedRoles: PropTypes.array,
};
export default UserListPage;
