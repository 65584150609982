import { Avatar } from "@mui/material";
export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
export const stringAvatar = (givenName, familyName) => {
  return `${givenName?.charAt(0)}${familyName?.charAt(0)}`;
};
function UserNameAvatar(data) {
  return (
    <Avatar
      src=""
      sx={{
        width: 40,
        height: 40,
        bgcolor: stringToColor(`${data.givenName} ${data.familyName}`),
        textTransform: "uppercase",
        fontSize: 15,
      }}
    >
      {stringAvatar(data.givenName, data.familyName)}
    </Avatar>
  );
}

export default UserNameAvatar;
