/* eslint react/prop-types: 0 */

import AddIcon from "@mui/icons-material/Add";
import { Box, Stack, Tooltip } from "@mui/material";
import "./RoleEntityShowMore.css";

function RoleEntityShowMore({ data }) {
  const tooltipTitle = data?.map((role, index) => {
    return index == 0 ? (
      ""
    ) : (
      <span key={role} data-testid="roleTitle">
        {role}
        {data.length - index == 1 ? "" : ","}
        <br />
      </span>
    );
  });
  const tooltipBase = data.map((role, index) => {
    return index < 1 ? (
      <span key={role} className="roleBase" data-testid="roleBase">
        {role}
      </span>
    ) : (
      ""
    );
  });

  return (
    <>
      <Stack direction="row" className="roleField">
        {tooltipBase}
        {data.length > 1 ? (
          <Tooltip title={tooltipTitle} onClick={(e) => e.preventDefault()}>
            <Box
              sx={{
                "&:hover": {
                  border: "0.5px solid #46BEAA !important",
                },
              }}
              className="tootlTipTitle"
              onClick={(e) => e.preventDefault()}
            >
              <AddIcon color="#386685" sx={{ alignSelf: "center", fontSize: "11px" }} />
              <span
                data-testid="outstanding-roles"
                style={{ alignSelf: "center", fontSize: "11px", marginRight: "1px" }}
              >
                {data.length - 1}
              </span>
            </Box>
          </Tooltip>
        ) : (
          ""
        )}
      </Stack>
    </>
  );
}

export default RoleEntityShowMore;
