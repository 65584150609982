import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import { Grid, Stack } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import "./DualListBoxSkeleton.css";
import { useTenant } from "../../contexts/TenantService";

function DualListBoxSkeleton() {
  const { tenantSvc } = useTenant();
  const isRoleDescriptionAvail = tenantSvc?.featureToggle?.showRolesDescription;
  const customOptions = () => {
    return (
      <List dense component="div" role="list">
        <ListItem role="listitem" button>
          <ListItemText>
            <Skeleton variant="rectangular" height={25} className="role-list-option" />
          </ListItemText>
        </ListItem>
        <ListItem role="listitem" button>
          <ListItemText>
            <Skeleton variant="rectangular" height={25} className="role-list-option" />
          </ListItemText>
        </ListItem>
        <ListItem role="listitem" button>
          <ListItemText>
            <Skeleton variant="rectangular" height={25} className="role-list-option" />
          </ListItemText>
        </ListItem>
        <ListItem role="listitem" button>
          <ListItemText>
            <Skeleton variant="rectangular" height={25} className="role-list-option" />
          </ListItemText>
        </ListItem>
      </List>
    );
  };
  return (
    <>
      <Paper sx={{ bgColor: "pink", mt: "10px" }}>
        <Stack direction="row">
          <Skeleton
            variant="rectangular"
            height={30}
            sx={{ margin: "8px 62px 8px 17px" }}
            className="role-list-option"
          />
          <Skeleton variant="rectangular" height={30} sx={{ m: "8px" }} className="role-list-option" />
        </Stack>
        <Stack direction="row">
          <Grid item>{customOptions()}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Skeleton variant="rectangular" width={25} height={25} sx={{ mt: "15px", mb: "8px" }} />
              <Skeleton variant="rectangular" width={25} height={25} sx={{ m: "8px" }} />
              <Skeleton variant="rectangular" width={25} height={25} sx={{ m: "8px" }} />
              <Skeleton variant="rectangular" width={25} height={25} sx={{ m: "8px" }} />
            </Grid>
          </Grid>
          <Grid item>{customOptions()}</Grid>
        </Stack>
      </Paper>
      {isRoleDescriptionAvail && <Skeleton variant="rectangular" width={400} height={30} sx={{ mt: "15px" }} />}
    </>
  );
}
export default DualListBoxSkeleton;
