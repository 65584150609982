import { Grid, Skeleton, Tooltip } from "@mui/material";
import { FormatDateTimeUI } from "../../helpers/FormatDateTime/FormatDateTime";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HistoryIcon from "@mui/icons-material/History";
import handleApiError from "../../helpers/HandleapiError/HandleapiError";
import { utcTimeZoneTip } from "../../helpers/TooltipsInfo/TooltipsInfo";
import { timezone } from "../../helpers/Constants/Constants";
function CreatedByInfo({ user }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const createdTimestamp = user?.createTimestamp;

  useEffect(() => {
    if (!user) {
      setLoading(true);
      return;
    }

    if (!("createTimestamp" in user)) {
      const error_msg = handleApiError(error);
      setError(error_msg);
      setLoading(false);
      return;
    }

    setLoading(false);
    setError(null);
  }, [user]);

  if (loading) {
    return (
      <Grid item xs={3} sm={4} md={5} lg={5}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1rem",
            width: "100%",
            height: "60px",
            minWidth: "250px",
            maxWidth: "550px",
          }}
          data-testid="skeleton"
        />
      </Grid>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Grid item xs={3} sm={4} md={5} lg={5} className="created-by-info">
      <div>
        <CalendarTodayIcon />
        <b>Created at</b> <br />{" "}
        <Tooltip title={utcTimeZoneTip}>
          <span className="created-by-name">{`${FormatDateTimeUI(createdTimestamp)} ${timezone}`} </span>
        </Tooltip>
      </div>
    </Grid>
  );
}

function ModifiedByInfo({ user }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const modifyTimestamp = user?.modifyTimestamp;

  useEffect(() => {
    if (!user) {
      setLoading(true);
      return;
    }

    if (!("modifyTimestamp" in user)) {
      const error_msg = handleApiError(error);
      setError(error_msg);
      setLoading(false);
      return;
    }

    setLoading(false);
    setError(null);
  }, [user]);

  if (loading) {
    return (
      <Grid item xs={3} sm={4} md={5} lg={5}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1rem",
            width: "100%",
            height: "60px",
            minWidth: "250px",
            maxWidth: "550px",
          }}
          data-testid="skeleton"
        />
      </Grid>
    );
  }

  if (loading) {
    return (
      <Grid item xs={3} sm={4} md={5} lg={5}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1rem",
            width: "100%",
            height: "60px",
            minWidth: "250px",
            maxWidth: "550px",
          }}
          data-testid="skeleton"
        />
      </Grid>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Grid item xs={3} sm={4} md={5} lg={5} className="modified-by-info">
      <div>
        <HistoryIcon />
        <b>Last updated at</b> <br />
        <Tooltip title={utcTimeZoneTip}>
          <span className="modified-by-name">{`${FormatDateTimeUI(modifyTimestamp)} ${timezone}`}</span>
        </Tooltip>
      </div>
    </Grid>
  );
}

export { CreatedByInfo, ModifiedByInfo };

CreatedByInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

ModifiedByInfo.propTypes = {
  user: PropTypes.object.isRequired,
};
