import { useQuery } from "@tanstack/react-query";
import RoleServiceProvider from "../../services/RoleServiceProvider/RoleServiceProvider";

export function RoleList() {
  const roleService = RoleServiceProvider();
  const {
    data: allRoles,
    isError,
    isFetching: isFetchingRoles,
    isLoading: isLoadingRoles,
    error,
  } = useQuery({
    queryKey: ["roles"],
    refetchOnWindowFocus: false,
    refetchOnMount: false,

    queryFn: async () => {
      const response = await roleService.getRoles("roles");
      return response;
    },
  });

  return { allRoles, isError, isFetchingRoles, isLoadingRoles, error };
}

export default RoleList;
