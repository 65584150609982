import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Stack, Grid } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./CustomAppBar.css";
import { stringToColor } from "../UserNameAvatar/UserNameAvatar";
import { stringAvatar } from "../UserNameAvatar/UserNameAvatar";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { UseIdentityUser } from "../../helpers/UseIdentityUser";
import { ProfileInfoSkeleton } from "../ProfileInfoSkeleton";
import { useContext, useEffect } from "react";
import { DataContext } from "../../contexts/DataContext";
import UserAddedSuccessPopUp from "../../features/UserAddedSuccessPopUp/UserAddedSuccessPopUp";
import UserEditedSuccessPopUp from "../../features/UserEditedSuccessPopUp/UserEditedSuccessPopUp";
import { useTenant } from "../../contexts/TenantService";
import handleApiError from "../../helpers/HandleapiError/HandleapiError";
function CustomAppBar() {
  const { authService } = useAuth();
  const { identityUser, error: identityUserError } = UseIdentityUser();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { successUserName, setSuccessUserName } = useContext(DataContext);
  const { successUserNameEdit, setSuccessUserNameEdit } = useContext(DataContext);
  const [error, setError] = React.useState("");
  useEffect(() => {
    const errorMessage = handleApiError(identityUserError);
    setError(errorMessage);
  }, [identityUserError]);
  useEffect(() => {
    setTimeout(() => {
      setSuccessUserName("");
      setSuccessUserNameEdit("");
    }, 7000);
  });

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navigate = useNavigate();
  const { tenant } = useParams();
  const { tenantSvc } = useTenant();

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box
              component="img"
              sx={{ height: 50 }}
              alt="logo"
              margin="10px"
              src={process.env.PUBLIC_URL + "/static/images/" + tenant + "/banner_logo.png"}
              data-testid="logo"
              className="logo-dynamic"
            />
            {tenantSvc?.environment !== "prod" && <Box className="environmentInfo">{tenantSvc?.environment}</Box>}
          </Box>

          <Grid item xs={3} sm={4} md={2} className="SuccessMessageGrid">
            {successUserName != "" && <UserAddedSuccessPopUp />}
            {successUserNameEdit != "" && <UserEditedSuccessPopUp />}
          </Grid>
          <Grid item xs={3} sm={4} md={2} className="ErrorMessageGrid app-bar">
            {error && identityUserError?.response?.status && <div className="global-error-msg">{error}</div>}
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0 }} className="topMenuBar">
            {identityUser ? (
              <Stack direction="row" onClick={handleOpenUserMenu} sx={{ cursor: "pointer" }} className="avatar-wrapper">
                <Box sx={{ fontSize: "16px", fontFamily: "'Nunito', sans-serif" }}>
                  {identityUser && identityUser?.givenName + " " + identityUser?.familyName}
                </Box>
                <KeyboardArrowDownRoundedIcon className="key-down-arrow" />

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    src={process.env.PUBLIC_URL + "/static/images/avatar/1.jpg"}
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: stringToColor(`${identityUser?.givenName} ${identityUser?.familyName}`),
                      textTransform: "uppercase",
                      fontSize: 15,
                      marginLeft: 1,
                    }}
                  >
                    {stringAvatar(identityUser?.givenName, identityUser?.familyName)}
                  </Avatar>
                </IconButton>
              </Stack>
            ) : (
              <ProfileInfoSkeleton />
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className="logoutMenu"
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu} data-testid="logout-menu">
                <Box
                  onClick={() => authService.logout()}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "4px 10px 4px 2px",
                    marginLeft: "-20px",
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "#0000000F",
                    },
                  }}
                  data-testid="logout-icon"
                >
                  <LogoutIcon sx={{ color: "#2E3358" }} />
                  <span>&nbsp; Logout</span>
                </Box>
                <Box
                  onClick={() => navigate(`/ciam/v1/${tenant}/profile`)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "10px 5px 7px 0px",
                    marginLeft: "-20px",

                    "&:hover": {
                      backgroundColor: "#0000000F",
                    },
                  }}
                >
                  <AccountCircleIcon style={{ color: "#2E3358" }} />
                  <span>&nbsp;&nbsp; My profile</span>
                </Box>
                {tenantSvc?.tenantId == "psa" && (
                  <Box
                    className="csmMenuPortalLink"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "10px 5px 4px 6px",
                      marginLeft: "-20px",

                      "&:hover": {
                        backgroundColor: "#0000000F",
                      },
                    }}
                  >
                    <a
                      href={tenantSvc?.authExitUrl}
                      style={{
                        textDecoration: "none",
                        fontFamily: "'Nunito', sans-serif",
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ExitToAppIcon style={{ color: "#2E3358" }} />
                      <span style={{ color: "#414141", fontSize: "14px" }}>&nbsp;&nbsp; Go to CSM GUI Portal</span>
                    </a>
                  </Box>
                )}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default CustomAppBar;
