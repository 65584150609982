import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { useTenant } from "../../contexts/TenantService";

function CredentialMgmtDataService() {
  const { authService } = useAuth();
  const { tenantSvc } = useTenant();
  const apiUrl = tenantSvc?.apiUrl;
  const tenantIdentifier = tenantSvc?.tenantId;
  const token = authService?.getAccessToken();

  const validateToken = (resource, params) => {
    return axios.post(`${apiUrl}/${tenantIdentifier}` + resource, params);
  };

  const activateUser = (resource, params) => {
    return axios.post(`${apiUrl}/${tenantIdentifier}` + resource, params);
  };

  const forgotPassword = (resource, params) => {
    return axios.post(`${apiUrl}/${tenantIdentifier}` + resource, params);
  };

  const changePassword = (resource, params) => {
    return axios.post(`${apiUrl}/${tenantIdentifier}` + resource, params, {
      headers: { Authorization: `bearer ${token}` },
    });
  };
  return {
    validateToken,
    activateUser,
    forgotPassword,
    changePassword,
  };
}

export default CredentialMgmtDataService;
