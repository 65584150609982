import RoleList from "../../hooks/RoleList/RoleList";
import roleUtility from "../RoleUtility/RoleUtility";

const UserRolesEntities = (userRolesIDs, isEntityPresent) => {
  const RolesList = RoleList();
  let roleEntityList;
  let roleSet = [];
  let entitySet = [];
  if (RolesList?.allRoles?.data) {
    roleEntityList = roleUtility.constructRolesAndEntitiesSet(RolesList?.allRoles?.data, isEntityPresent);
  }

  roleEntityList?.rolesOnly?.map((role) => {
    userRolesIDs?.map((userRoleId) => {
      if (role.id == userRoleId) {
        roleSet.push({
          role: role.name,
          description: role.description,
        });
      }
    });
  });
  roleEntityList?.entitiesOnly?.map((role) => {
    userRolesIDs?.map((userRoleId) => {
      if (role.id == userRoleId) {
        entitySet.push({
          role: role.name,
          description: role.description,
        });
      }
    });
  });
  return { roleSet, entitySet };
};

export default UserRolesEntities;
