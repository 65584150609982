import { useMemo } from "react";
import PropTypes from "prop-types";
import { BaseTable } from "../../components/BaseTable";

const OwnRolesPermissions = ({ data, setTotalRoles, setTotalEntities, isEntityTable }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "role",
        header: isEntityTable ? "Entity" : "Role",
        size: 600,
      },
      {
        accessorKey: "description",
        header: "Description",
        grow: true,
        size: 850,
        Cell: ({ cell }) => <span className="description-cell-span">{cell.row.original.description}</span>,
      },
    ],
    [isEntityTable]
  );

  const setTotalData = (data) => {
    if (setTotalRoles) setTotalRoles(data);
    if (setTotalEntities) setTotalEntities(data);
  };

  return <BaseTable columns={columns} data={data} setTotalData={setTotalData} />;
};

export default OwnRolesPermissions;
OwnRolesPermissions.propTypes = {
  data: PropTypes.array,
  setTotalRoles: PropTypes.func,
  setTotalEntities: PropTypes.func,
  isEntityTable: PropTypes.bool,
  cell: PropTypes.object,
};
