const handleDualListBoxClick = (e, element, roles, setClickedRoleValue, setClickedEntityValue) => {
  const parent = e.target?.parentNode;
  let count = 0;
  if (element.tagName?.toLowerCase() === "option") {
    const selectElement = parent.closest(".rdl-control");
    const options = selectElement.options;
    for (let i = 0; i < options?.length; ++i) {
      if (options[i].selected) {
        count++;
      }
    }
    if (count == 1) {
      roles?.map((x) => {
        if (x.id == element.getAttribute("data-real-value").slice(1, -1)) {
          if (
            (parent && parent.matches && parent.matches("#entities-available")) ||
            parent.matches("#entities-selected")
          ) {
            setClickedEntityValue(x.description ? x.description : "No description available");
          } else {
            setClickedRoleValue(x.description ? x.description : "No description available");
          }
          return x;
        }
      });
    } else if (count == 0) {
      setClickedRoleValue("");
      setClickedEntityValue("");
    } else {
      if ((parent && parent.matches && parent.matches("#entities-available")) || parent.matches("#entities-selected")) {
        setClickedEntityValue("Please select maximum of 1 Entity");
      } else {
        setClickedRoleValue("Please select maximum of 1 role");
      }
    }
  } else if (element.tagName?.toLowerCase() == "button" || element.tagName?.toLowerCase() == "span") {
    setClickedRoleValue("");
    setClickedEntityValue("");
  } else if (element.tagName?.toLowerCase() == "select") {
    if (element.matches("#entities-available") || element.matches("#entities-selected")) {
      setClickedEntityValue("Please select maximum of 1 Entity");
    } else {
      setClickedRoleValue("Please select maximum of 1 role");
    }
  }
};
export default handleDualListBoxClick;
