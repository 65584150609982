import UserServiceProvider from "../../services/UserServiceProvider/UserServiceProvider";
import { useQuery } from "@tanstack/react-query";

function useGetUsersList() {
  const userService = UserServiceProvider();
  const {
    data,
    isError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
    error,
  } = useQuery({
    queryKey: ["users"],
    refetchOnWindowFocus: false,
    refetchOnMount: true,

    queryFn: async () => {
      const response = await userService.getUsers("users");
      return response;
    },
  });

  return { data, isError, isFetchingUsers, isLoadingUsers, error };
}

export default useGetUsersList;
