const defaultValue = "Select";
function GetSelectedLabel(array) {
  if (!array || array.length == 0) {
    return defaultValue;
  }
  if (array.length == 1) {
    return "1 value selected";
  }
  return `${array.length} values selected`;
}

export default GetSelectedLabel;
