import { createContext, useContext, useMemo, useState } from "react";
import AuthService, { SessionTracker } from "../services/AuthService";
import PropTypes from "prop-types";
import { useTenant } from "./TenantService";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const { tenantSvc } = useTenant();
  const [authService, setAuthService] = useState(new AuthService());
  const [sessionTracker, setSessionTracker] = useState({});
  const [profile, setProfile] = useState();
  useMemo(() => {
    if (tenantSvc) {
      let authSvc = new AuthService(
        tenantSvc?.authServerUrl,
        tenantSvc?.authRealm,
        tenantSvc?.authClient,
        tenantSvc?.authLogoutRedirectUrl,
        tenantSvc?.authTokenRefreshInterval
      );
      setAuthService(authSvc);

      const tracker = new SessionTracker({
        timeout: tenantSvc?.authSessionIdleTimeout,
        onTokenExpired: async () => {
          try {
            const result = await authSvc.updateToken();
            return result;
          } catch (error) {
            console.log(error);
          }
        },
      });

      setSessionTracker(tracker);
    }
  }, [tenantSvc]);

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated, profile, setProfile, authService, sessionTracker }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
