import { useEffect, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import PropTypes from "prop-types";

const BaseTable = ({ columns, data, setTotalData, customTableOptions = {} }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    if (typeof window !== "undefined" || !data) {
      setIsLoading(false);
    }
  }, [data]);

  const defaultTableOptions = {
    columns,
    data,
    enableColumnResizing: true,
    layoutMode: "grid",
    enableGlobalFilterModes: true,
    enablePagination: false,
    enableColumnPinning: false,
    enableRowNumbers: true,
    onSortingChange: setSorting,
    state: { isLoading: isLoading, sorting },
    enableHiding: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFilterMatchHighlighting: true,
    muiSkeletonProps: { animation: "wave" },
    globalFilterFn: "contains",
    initialState: { showGlobalFilter: true },
    muiTablePaperProps: ({ table }) => ({
      style: { top: table.getState().isFullScreen ? 100 : 0 },
    }),
  };

  const tableOptions = { ...defaultTableOptions, ...customTableOptions };
  const table = useMaterialReactTable(tableOptions);

  const totalRowsBeforeFiltering = data?.length ?? 0;
  const filteredData = table?.getFilteredRowModel()?.rows;
  const totalRowsAfterFiltering = filteredData?.length || 0;

  useEffect(() => {
    if (setTotalData) {
      setTotalData({ before: totalRowsBeforeFiltering, after: totalRowsAfterFiltering });
    }
  }, [totalRowsAfterFiltering, setTotalData, totalRowsBeforeFiltering]);

  return <MaterialReactTable table={table} data-testid="report-table" data-is-loading={isLoading} />;
};

BaseTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  setTotalData: PropTypes.func,
  customTableOptions: PropTypes.object,
};

export default BaseTable;
