import { Alert, Box, Stack, Button, Modal, AlertTitle, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./OnBoardPrecheckContent.css";
import { CenterLoadingSpinner } from "../Spinners/LoadSpinner";
import WarningIcon from "@mui/icons-material/Warning";
import { useAuth } from "../../contexts/AuthContext";
import { useState } from "react";
import { resetOTP } from "../../services/TwoFaModalApi/TwoFaModalApi";
import CloseIcon from "@mui/icons-material/Close";
import { CustomizeDateTime } from "../../helpers";
import { useParams } from "react-router-dom";
import { useTenant } from "../../contexts/TenantService";

function OnBoardPrecheckContent({
  preCheckStatus,
  showModal,
  setShowModal,
  awaitResponse,
  confirmResetModal,
  setConfirmResetModal,
  setAwaitResponse,
  setOnBoardingError,
  onBoardingError,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const { authService } = useAuth();
  const { tenant } = useParams();
  const [showOTPResetSuccess, setShowOTPSuccess] = useState(false);
  const token = authService?.getAccessToken();
  const { tenantSvc } = useTenant();
  const tenantIdentifier = tenantSvc?.tenantId;
  const [display2FAError, setDisplay2FAErrorModal] = useState(false);
  const Reset2FA = (userID) => {
    setConfirmResetModal(false);
    setAwaitResponse({ isLoading: true, msg: "Resetting user's 2FA. Please wait..." });
    resetOTP(userID, `${tenantSvc?.apiUrl}/${tenantIdentifier}${tenantSvc?.otpResetUrlPath}`, token)
      .then(() => {
        setShowOTPSuccess(true);
        setAwaitResponse({ isLoading: false, msg: "" });
      })
      .catch(() => {
        setDisplay2FAErrorModal(true);
        setAwaitResponse({ isLoading: false, msg: "" });
      });
  };
  let modal_id = `${tenant}-theme`;
  let dateTime;
  if (preCheckStatus.statusCode == 200 && preCheckStatus.statusCode != 0) {
    dateTime = CustomizeDateTime(preCheckStatus?.data?.createdDate);
  }

  if (awaitResponse?.isLoading) {
    return (
      <Modal open={awaitResponse.isLoading} className="MFAModel wl-theme" id={modal_id}>
        <Box style={style}>
          <Grid container className="twoFAHeader">
            <Grid item xs={11} sx={{ pl: 1, color: "#fff", fontWeight: "bold" }}>
              <Typography variant="h6">Manage Two-Factor Authentication</Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                sx={{ color: "#fff", cursor: "pointer", textAlign: "right" }}
                onClick={() => setShowModal(false)}
              />
            </Grid>
          </Grid>
          {awaitResponse.msg != "" && <p style={{ padding: "20px 20px 0 20px" }}>{awaitResponse.msg}</p>}
          <CenterLoadingSpinner />
        </Box>
      </Modal>
    );
  }

  if (showOTPResetSuccess) {
    return (
      <Modal open={showOTPResetSuccess} className="MFAModel wl-theme" id={modal_id}>
        <Box style={style}>
          <Grid container className="twoFAHeader">
            <Grid item xs={11} sx={{ pl: 1, color: "#fff", fontWeight: "bold" }}>
              <Typography variant="h6">Manage Two-Factor Authentication</Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                sx={{ color: "#fff", cursor: "pointer", textAlign: "right" }}
                onClick={() => setShowOTPSuccess(false)}
              />
            </Grid>
          </Grid>
          <Stack sx={{ padding: "20px" }} className="successMessage">
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              OTP reset successful
            </Alert>
          </Stack>
          <hr style={{ border: "1px solid rgb(128 128 128 / 23%)", marginTop: "20px" }} />
          <Stack direction="row" spacing={2} className="twoFA_btn_container">
            <Button
              className="twoFA_success_btn"
              variant="outlined"
              color="success"
              sx={{ borderRadius: "20px" }}
              onClick={() => setShowOTPSuccess(false)}
            >
              Ok, Thanks
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  }

  if (onBoardingError) {
    return (
      <Modal open={onBoardingError} className="MFAModel wl-theme" id={modal_id}>
        <Box style={style}>
          <Grid container className="twoFAHeader">
            <Grid item xs={11} sx={{ pl: 1, color: "#fff", fontWeight: "bold" }}>
              <Typography variant="h6">Manage Two-Factor Authentication</Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                sx={{ color: "#fff", cursor: "pointer", textAlign: "right" }}
                onClick={() => {
                  setOnBoardingError(false);
                  setShowModal(false);
                }}
              />
            </Grid>
          </Grid>
          <Stack spacing={2} sx={{ padding: "20px" }}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {preCheckStatus.data}
            </Alert>
          </Stack>
          <hr style={{ border: "1px solid rgb(128 128 128 / 23%)", marginTop: "20px" }} />
          <Stack direction="row" spacing={2} className="twoFA_btn_container">
            <Button
              className="twoFA_cancel_btn"
              variant="outlined"
              color="error"
              sx={{ borderRadius: "20px" }}
              onClick={() => {
                setOnBoardingError(false);
                setShowModal(false);
              }}
            >
              Ok
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  }

  if (display2FAError) {
    return (
      <Modal open={display2FAError} className="MFAModel wl-theme" id={modal_id}>
        <Box style={style}>
          <Grid container className="twoFAHeader">
            <Grid item xs={11} sx={{ pl: 1, color: "#fff", fontWeight: "bold" }}>
              <Typography variant="h6">Manage Two-Factor Authentication</Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                sx={{ color: "#fff", cursor: "pointer", textAlign: "right" }}
                onClick={() => setDisplay2FAErrorModal(false)}
              />
            </Grid>
          </Grid>
          <Stack spacing={2} sx={{ padding: "20px" }}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Oops! Something went wrong. Please contact your support team.
            </Alert>
          </Stack>
          <hr style={{ border: "1px solid rgb(128 128 128 / 23%)", marginTop: "20px" }} />
          <Stack direction="row" spacing={2} className="twoFA_btn_container">
            <Button
              className="twoFA_cancel_btn"
              variant="outlined"
              color="error"
              sx={{ borderRadius: "20px" }}
              onClick={() => setDisplay2FAErrorModal(false)}
            >
              Ok
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  }

  if (!confirmResetModal && !showOTPResetSuccess) {
    return (
      <Modal open={showModal} className="MFAModel wl-theme" id={modal_id}>
        {preCheckStatus.statusCode !== 0 && !awaitResponse.isLoading ? (
          <Box style={style}>
            <Grid container className="twoFAHeader">
              <Grid item xs={11} sx={{ pl: 1, color: "#fff", fontWeight: "bold" }}>
                <Typography variant="h6">Manage Two-Factor Authentication</Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon
                  sx={{ color: "#fff", cursor: "pointer", textAlign: "right" }}
                  onClick={() => setShowModal(false)}
                />
              </Grid>
            </Grid>
            {preCheckStatus.statusCode == 404 && preCheckStatus.statusCode != 0 ? (
              <Stack sx={{ mt: 1 }} className="twoFAContainer">
                <Alert severity="warning">
                  It appears user has not set the 2FA. Please inform the user to continue to set the 2FA by scanning the
                  QR code.
                </Alert>
                <Stack sx={{ color: "#414141", fontSize: "16px" }}>
                  <p>
                    Do you still want to reset 2FA for the user
                    <span style={{ color: "#41B4D2" }}>&nbsp; {preCheckStatus?.userData?.name} </span>|
                    <span style={{ color: "#41B4D2" }}>
                      &nbsp; {preCheckStatus?.userData?.givenName} {preCheckStatus?.userData?.familyName}
                    </span>
                    &nbsp;?
                  </p>
                </Stack>
              </Stack>
            ) : (
              <Stack className="twoFAContainer">
                <Alert severity="info">
                  It appears user <b>{preCheckStatus?.userData?.name}</b> |
                  <b>
                    &nbsp;{preCheckStatus?.userData?.givenName} {preCheckStatus?.userData?.familyName}
                  </b>
                  &nbsp;has already set the 2FA on <b>{dateTime?.date}</b> at {dateTime?.time}&nbsp;
                  {preCheckStatus?.data?.label !== "" && `on a device -  ${preCheckStatus?.data?.label}`}&nbsp;
                  {preCheckStatus?.data?.credentialType !== "" &&
                    `using credential type as ${preCheckStatus?.data?.credentialType}`}
                  .
                </Alert>
                <p>Are you sure to reset 2FA for this user?</p>
              </Stack>
            )}
            <hr style={{ border: "1px solid rgb(128 128 128 / 23%)", marginTop: "20px" }} />
            <Stack direction="row" spacing={2} className="twoFA_btn_container">
              <Button
                className="twoFA_cancel_btn"
                variant="outlined"
                color="error"
                sx={{ borderRadius: "20px" }}
                onClick={() => setShowModal(false)}
              >
                No, Thanks
              </Button>
              <Button
                variant="contained"
                sx={{ borderRadius: "20px" }}
                onClick={() => {
                  setShowModal(false);
                  setConfirmResetModal(true);
                  setAwaitResponse({ isLoading: false, msg: "" });
                }}
                className="two_fa_btn_color_proceed"
              >
                Yes, proceed
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box style={style}>
            <Grid container className="twoFAHeader">
              <Grid item xs={11} sx={{ pl: 1, color: "#fff", fontWeight: "bold" }}>
                <Typography variant="h6">Manage Two-Factor Authentication</Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon
                  sx={{ color: "#fff", cursor: "pointer", textAlign: "right" }}
                  onClick={() => setShowModal(false)}
                />
              </Grid>
            </Grid>
            <CenterLoadingSpinner />
          </Box>
        )}
      </Modal>
    );
  }

  if (confirmResetModal) {
    return (
      <Modal open={confirmResetModal} className="MFAModel wl-theme" id={modal_id}>
        <Box style={style}>
          <Grid container className="twoFAHeader">
            <Grid item xs={11} sx={{ pl: 1, color: "#fff", fontWeight: "bold" }}>
              <Typography variant="h6">Manage Two-Factor Authentication</Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                sx={{ color: "#fff", cursor: "pointer", textAlign: "right" }}
                onClick={() => setConfirmResetModal(false)}
              />
            </Grid>
          </Grid>
          <Stack>
            <span style={{ display: "flex", padding: "20px 20px 10px" }} className="warningMessage">
              <WarningIcon className="warning" sx={{ color: "#ffa500e6", margin: " 5px 10px 0 0" }} /> User will be
              required to re-register for 2FA on the next login attempt.
            </span>
          </Stack>
          <hr style={{ border: "1px solid rgb(128 128 128 / 23%)", marginTop: "20px" }} />
          <Stack direction="row" spacing={2} className="twoFA_btn_container">
            <Button
              className="twoFA_cancel_btn"
              variant="outlined"
              color="error"
              sx={{ borderRadius: "20px" }}
              onClick={() => setConfirmResetModal(false)}
            >
              No, Thanks
            </Button>
            <Button
              variant="contained"
              sx={{ borderRadius: "20px" }}
              onClick={() => {
                setConfirmResetModal(false);
                Reset2FA(preCheckStatus?.userData.id);
                setShowModal(false);
                setAwaitResponse({ isLoading: true, msg: "" });
              }}
              className="two_fa_btn_color_proceed"
            >
              Yes, proceed
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  }

  if (preCheckStatus.statusCode != 0 && preCheckStatus.statusCode != 404) {
    return (
      <Modal className="MFAModel wl-theme" id={modal_id}>
        <Box style={style}>
          <Grid container className="twoFAHeader">
            <Grid item xs={11} sx={{ pl: 1, color: "#fff", fontWeight: "bold" }}>
              <Typography variant="h6">Manage Two-Factor Authentication</Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                sx={{ color: "#fff", cursor: "pointer", textAlign: "right" }}
                onClick={() => setShowModal(false)}
              />
            </Grid>
          </Grid>
          <Stack spacing={2} sx={{ padding: "20px" }}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {preCheckStatus.data}
            </Alert>
          </Stack>
          <hr style={{ border: "1px solid rgb(128 128 128 / 23%)", marginTop: "20px" }} />
          <Stack direction="row" spacing={2} className="twoFA_btn_container">
            <Button
              className="twoFA_cancel_btn"
              variant="outlined"
              color="error"
              sx={{ borderRadius: "20px" }}
              onClick={() => setShowModal(false)}
            >
              Ok
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  }
}
OnBoardPrecheckContent.propTypes = {
  preCheckStatus: PropTypes.object,
  open: PropTypes.bool,
  awaitResponse: PropTypes.bool,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  setConfirmResetModal: PropTypes.func,
  confirmResetModal: PropTypes.bool,
  setAwaitResponse: PropTypes.func,
  setOnBoardingError: PropTypes.func,
  onBoardingError: PropTypes.bool,
};
export default OnBoardPrecheckContent;
