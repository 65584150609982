import { useParams } from "react-router";
import CredMgmtForgotPassword from "../../components/CredMgmtForgotPassword/CredMgmtForgotPassword";

function CredMgmtForgotPasswordPage() {
  const { tenant } = useParams();
  const theme = `${tenant}-theme`;
  return (
    <>
      <div className={theme} data-testid="theme-name">
        <CredMgmtForgotPassword />
      </div>
    </>
  );
}

export default CredMgmtForgotPasswordPage;
