import "./Footer.css";
import { useConfig } from "../../contexts/ConfigContext";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function Footer() {
  const { config } = useConfig();
  const location = useLocation();
  const [urlClass, setUrlClass] = useState(window.location.href.split("#")[0]);

  useEffect(() => {
    setUrlClass(window.location.href.split("#")[0]);
  }, [location]);

  return (
    <footer className="WL-Footer" data-testid="footer-component" id={urlClass}>
      &copy; Worldline {new Date().getFullYear()}. All rights reserved | v{config?.version}
    </footer>
  );
}

export default Footer;
