export function CustomizeDateTime(field) {
  var fields = field?.split("T");
  var date = fields && fields[0];
  var time = fields && fields[1]?.split(":", 2)?.join(":");

  return {
    date,
    time,
  };
}
